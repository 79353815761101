import { Skeleton, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

export type LoadingSkeletonProps = {
  loading?: boolean;
  children: React.ReactNode;
};
export const LoadingSkeleton: React.FC<LoadingSkeletonProps> = ({ loading, children }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  if (loading) {
    return (
      <Skeleton variant="rectangular" height={isSmall ? 600 : 400 } width="100%" />
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

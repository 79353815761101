import { CardContentMarkdown, MarkdownInput, SectionCard, SectionCardRow, TextInput } from 'components';
import React from 'react';
import { ProductPageSection, ProductPageSectionRowId } from '../types';
import { useProductOutletContext } from '../Product.base';
import { ProductSectionCardRowEditable } from '../components/ProductSectionCardRow.component';
import { updateProduct } from 'api/actions';
import { mixed, number, object, string } from 'yup';
import { ProductNameForm } from '../forms/ProductName.form';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { DateService, currencyFormatter } from 'services';
import { processFormValueUpdate } from 'helpers';
import { ProductActiveBaselineTypeEnum } from 'api/resources';
import { productEnumHelpers } from 'helpers/enums/product-enums.helpers';
import { ProductBaselineForm } from '../forms/ProductBaseline.form';
import { CheckCircleOutline, RadioButtonUnchecked } from '@mui/icons-material';

export const OverviewSection: React.FC = () => {
  const { product } = useProductOutletContext();

  return (
    <SectionCard title="Overview" id={ProductPageSection.OVERVIEW}>
      <ProductSectionCardRowEditable
        title="Name"
        rowId={ProductPageSectionRowId.NAME}
        formikProps={{
          initialValues: { name: product.name, sku: product.sku },
          onSubmit: (values) => updateProduct(product._id, { name: values.name.trim(), sku: values.sku.trim() }),
          validationSchema: object({ name: string().required('Product Name Required'), sku: string().required('Sku Required') })
        }}
        form={<ProductNameForm />}
      >
        <Stack gap={1}>
          <Typography>{product.name}</Typography>
          <Typography variant="body2" color="text.secondary">SKU: {product.sku}</Typography>
        </Stack>
      </ProductSectionCardRowEditable>
      <ProductSectionCardRowEditable
        title="Price"
        rowId={ProductPageSectionRowId.PRICE}
        formikProps={{
          initialValues: { price: product.price },
          onSubmit: (values) => updateProduct(product._id, { price: values.price }),
          validationSchema: object({ price: number().required('Product Price Required').min(1, 'Price must be greater than 0') })
        }}
        form={(
          <TextInput
            id="price"
            name="price"
            label="Price *"
            type="number"
          />
        )}
      >
        {currencyFormatter.format(product.price)}
      </ProductSectionCardRowEditable>
      <ProductSectionCardRowEditable
        title="Category"
        rowId={ProductPageSectionRowId.CATEGORY}
        formikProps={{
          initialValues: { category: product.category },
          onSubmit: (values) => updateProduct(product._id, { category: values.category }),
          validationSchema: object({ category: string().required('Category Required'), })
        }}
        form={(
          <TextInput
            id="category"
            name="category"
            label="Category *"
          />
        )}
      >
        {product.category}
      </ProductSectionCardRowEditable>
      <SectionCardRow title="Sales Rank">{`#${product.salesRank} `}<Typography component="span" variant="body2" color="text.secondary">{`(${product.salesCount} sales)`}</Typography></SectionCardRow>
      <ProductSectionCardRowEditable
        title="Baseline"
        rowId={ProductPageSectionRowId.BASELINE}
        formikProps={{
          initialValues: { manualBaseline: product.manualBaseline ?? 0, activeBaselineType: product.activeBaselineType },
          onSubmit: (values) => updateProduct(product._id, values),
          validationSchema: object({  manualBaseline: number().default(0), activeBaselineType: mixed<ProductActiveBaselineTypeEnum>().oneOf(productEnumHelpers.activeBaselineType.enumValues) })
        }}
        form={<ProductBaselineForm />}
        formFullWidth
      >
        <Stack gap={2}>
          <BaselineDisplay
            teamValue={product.autoBaseline}
            warehouseValue={product.autoWarehouseBaseline}
            label="Automatic"
            checked={product.activeBaselineType === ProductActiveBaselineTypeEnum.auto}
          />
          {(product.manualBaseline !== undefined && product.manualWarehouseBaseline !== undefined) && (
            <BaselineDisplay
              teamValue={product.manualBaseline}
              warehouseValue={product.manualWarehouseBaseline}
              label="Manual"
              checked={product.activeBaselineType === ProductActiveBaselineTypeEnum.manual}
            />
          )}
        </Stack>
      </ProductSectionCardRowEditable>
      <ProductSectionCardRowEditable
        title="Notes"
        rowId={ProductPageSectionRowId.NOTES}
        formikProps={{
          initialValues: { notes: product.notes ?? '' },
          onSubmit: (values) => updateProduct(product._id, { notes: processFormValueUpdate.string(values.notes) }),
          validationSchema: object({ category: string().default(''), })
        }}
        form={<MarkdownInput label="Notes" name="notes" />}
        formFullWidth
      >
        <CardContentMarkdown markdown={product.notes} />
      </ProductSectionCardRowEditable>
      {product.createdBy && <SectionCardRow title="Created By">{product.createdBy?.name}</SectionCardRow>}
      <SectionCardRow title="Created At">{DateService.getFormattedDate(product.createdAt, 'MM-D-YY')}</SectionCardRow>
      <SectionCardRow title="Created By">{product.createdBy?.name}</SectionCardRow>
    </SectionCard>
  );
};

type BaselineDisplayProps = {
  checked: boolean;
  teamValue: number;
  warehouseValue: number;
  label: string;
};

const BaselineDisplay: React.FC<BaselineDisplayProps> = ({ checked, teamValue, warehouseValue, label }) => {
  return (
    <Box display="flex" gap={1} alignItems="flex-start">
      <Typography
        color={theme => !checked ? theme.palette.grey[400] : undefined}
        fontWeight={600}
        textAlign="end"
      >
        {teamValue}&nbsp;
        <Typography variant="caption" color="text.secondary" component="div">TEAM</Typography>
      </Typography>
      <Divider orientation="vertical" flexItem />
      <Typography
        color={theme => !checked ? theme.palette.grey[400] : undefined}
        fontWeight={600}
        textAlign="start"

      >
        {warehouseValue}&nbsp;
        <Typography variant="caption" color="text.secondary"component="div">WH</Typography>
      </Typography>
      {checked ? <CheckCircleOutline color="primary" /> : <RadioButtonUnchecked color="disabled" />}
      <Typography color={theme => !checked ? theme.palette.grey[400] : undefined} fontWeight={checked ? 600 : 500}>{label}</Typography>
    </Box>
  );
};

import { Box, Button, Card, Divider, Pagination, Typography } from '@mui/material';
import { GetProductsResponse } from 'api/actions';
import { StickyStackItem } from 'components';
import { useContainerQuery } from 'hooks';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ProductionScheduleProduct } from '../ProductionSchedule.page';
import { ProductImageSlider } from './ProductImageSlider.component';
import { EastOutlined, SouthOutlined } from '@mui/icons-material';

export type ProductionCardsProps = {
  products: ProductionScheduleProduct[];
  search: string;
  setLogRunProductId: (id: string) => void;
};
export const ProductionCards: React.FC<ProductionCardsProps> = ({ products, search, setLogRunProductId }) => {
  const containerRef = useRef<HTMLElement>(null);
  const isContainerXs = useContainerQuery({ containerRef, query: theme => theme.breakpoints.down('sm') });
  const isContainerSm = useContainerQuery({ containerRef, query: theme => theme.breakpoints.down('md') });
  const isContainerMd = useContainerQuery({ containerRef, query: theme => theme.breakpoints.down('lg') });

  const cardsPerRowCount = useMemo(() => {
    if (isContainerXs) {
      return 1;
    }

    if (isContainerSm) {
      return 3;
    }

    if (isContainerMd) {
      return 4;
    }

    return 5;
  }, [ isContainerMd, isContainerSm, isContainerXs ]);

  const [ nextProducts, restProducts ] = useMemo(() => {
    return [ products.slice(0, cardsPerRowCount), products.slice(cardsPerRowCount) ];
  }, [ cardsPerRowCount, products ]);

  const [ page, setPage ] = useState(1);
  const isSearch = !!search;
  const cardsOnPage = cardsPerRowCount * (isSearch ? 2 : 1);
  const pageCount = Math.ceil((isSearch ? products : restProducts).length / cardsOnPage);

  useEffect(() => {
    setPage(1);
  }, [ search ]);

  return (
    <Box width="100%" height="100%" ref={containerRef}>
      <Box display="flex" flexDirection="column" height="100%">
        {!isSearch && (
          <>
            <Box mx={-3} mt={1}><Divider /></Box>
            <Box bgcolor="primary.background" mx={-3} px={3} pt={1}>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: `repeat(${cardsPerRowCount}, 1fr)`,
                  px: 2,
                  gap: 4,
                }}
              >
                <Box display="flex" flexDirection="column" alignItems="center" color="primary.main">
                  <Typography variant="h4" sx={{ textDecoration: 'underline', fontSize: { xs: 16, md: 20, lg: 24 } }}>
                    NEXT
                  </Typography>
                  <SouthOutlined fontSize="small"/>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: `repeat(${cardsPerRowCount}, 1fr)`,
                  alignItems: 'start',
                  gap: 0,
                  p: 2,
                  mr: -4,
                  pb: { xs: 1, md: 2, lg: 4 },
                }}
              >
                {nextProducts.map((product, index) => (
                  <Box key={product._id} display="flex" overflow="hidden">
                    <Card sx={theme => ({  width: '100%', border: index === 0 ? `2px solid ${theme.palette.primary.main}` : undefined })}>
                      <ProductCardContent
                        key={index}
                        index={product.index}
                        product={product}
                        isPrimary
                        onLogRun={() => setLogRunProductId(product._id)}
                      />
                    </Card>
                    <Box width={32} mx="auto" mt={17} display="flex" justifyContent="center" color="primary.main" fontSize="30px">
                      {index < cardsPerRowCount - 1 && <EastOutlined fontSize="small"/>}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box mx={-3}><Divider /></Box>
          </>
        )}
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: `repeat(${cardsPerRowCount}, 1fr)`,
            alignItems: 'start',
            gap: 4,
            padding: 2,
            position: 'relative',
          }}
        >
          {(isSearch ? products : restProducts)
            .slice((page - 1) * cardsOnPage, page * cardsOnPage)
            .map((product) => (
              <Card key={product._id}>
                <ProductCardContent
                  index={product.index}
                  key={product._id}
                  product={product}
                  isPrimary={!!search}
                  onLogRun={() => setLogRunProductId(product._id)}
                />
              </Card>
            ))}
        </Box>
      </Box>
      <StickyStackItem placement="bottom" order={0}>
        <Box width="100%" bgcolor="background.default">
          <Divider sx={{ mx: -3 }} />
          <Box py={1} display="flex" justifyContent="center" width="100%">
            <Pagination count={pageCount} page={page} onChange={(_, page) => setPage(page)} size="large" showFirstButton showLastButton />
          </Box>
        </Box>
      </StickyStackItem>
    </Box>
  );
};


type ProductCardContentProps = {
  index: number;
  product: GetProductsResponse['data'][number] & {
    inProduction: number;
    toProduce: number;
  };
  isPrimary?: boolean;
  onLogRun: () => void;
};
const ProductCardContent: React.FC<ProductCardContentProps> = ({ index, product, isPrimary, onLogRun }) => {
  return (
    <Box position="relative">
      <Typography variant="h5" position="absolute" top={5} right={10} color="text.secondary">#{index + 1}</Typography>
      <ProductImageSlider src={product.imageUrl} sizePx={300} grayScale={!isPrimary} />
      <Box mx={-1}><Divider /></Box>
      <Box display="flex" flexDirection="column" gap={1} p={1}>
        <Typography
          mx={1}
          textAlign="center"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          variant="h6"
        >
          {product.name}
        </Typography>
        <Box display="flex" gap={1} mx={2}>
          <Box flex={1} p={1}>
            <Typography textAlign="center" color="text.secondary">
              In prod.
            </Typography>
            <Typography variant="h6" textAlign="center" color="text.secondary">
              {product.inProduction}
            </Typography>
          </Box>
          <Box borderRadius={1} bgcolor={`${isPrimary ? 'primary' : 'unknown'}.background`} flex={1} p={1} border={theme => `1px solid ${isPrimary ? theme.palette.primary.main : theme.palette.unknown.main}`}>
            <Typography textAlign="center" color={`${isPrimary ? 'primary' : 'unknown'}.main`}>
              To produce
            </Typography>
            <Typography variant="h6" textAlign="center" color={`${isPrimary ? 'primary' : 'unknown'}.main`}>
              {product.toProduce}
            </Typography>
          </Box>
        </Box>
        <Box mx={-1}><Divider /></Box>
        <Button variant={isPrimary ? 'contained' : 'outlined'} onClick={onLogRun} color={isPrimary ? 'primary' : 'unknown'}>Log Run</Button>
      </Box>
    </Box>
  );
};

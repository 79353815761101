import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from './query-keys';
import { DashboardSectionEnum, getDashboardData, GetDashboardDataResponse } from 'api/actions';

export const useDashboardData = <S extends DashboardSectionEnum>(section: S) => {
  return useQuery<GetDashboardDataResponse<S>['data']>(QUERY_KEY.DASHBOARD(section), async () => {
    const response = await getDashboardData(section);

    return response.data;
  });
};
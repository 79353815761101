import React, { useCallback, useMemo } from 'react';
import { InventoryBatchLayout } from '../components/InventoryBatchLayout.component';
import { Button } from '@mui/material';
import { BeforeUnloadPrompt, InventoryEdits, InventoryTableApi, InventoryTableEditableMode, InventoryTableSettingsModal, InventoryTableStandardFiltersInput, PreviosQuantitiesCell, StandardInventoryTableFilters, Table, useAlertSnackbar, useInventoryTable } from 'components';
import { InventoryBatchStatusEnum, InventoryBatchTypeEnum, UserEmployeeRoleEnum } from 'api/resources';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCurrentUser, useProductsPageContext } from 'contexts';
import { useInventoryBatchOutletContext } from '../InventoryBatch.base';
import { GetProductsResponse, updateInventoryBatch, UpdateInventoryBatchInput, UpdateInventoryBatchStatusEnum } from 'api/actions';
import { QUERY_KEY } from 'queries/query-keys';
import { AxiosError } from 'axios';
import { getInitialTransferConfig, prepareReviewInventoryEdits } from '../helpers';
import { CancelBatchButton } from '../components/CancelBatchButton.component';
import { GridColDef } from '@mui/x-data-grid';
import { CancelReviewButton } from '../components/CancelReviewButton.component';

export const ManagerReview = () => {
  const { isAuthorizedEmployee } = useCurrentUser();
  const snackbar = useAlertSnackbar();
  const { teams, getProductsSortedAlphabetically } = useProductsPageContext();
  const warehouseTeamId = teams.filter(team => team.isWarehouse)[0]._id;
  const { inventoryBatch } = useInventoryBatchOutletContext();
  const queryClient = useQueryClient();
  const products = useMemo(getProductsSortedAlphabetically, [ getProductsSortedAlphabetically ]);
  const initialTransferConfig = useMemo(() => getInitialTransferConfig(inventoryBatch, warehouseTeamId), [ inventoryBatch, warehouseTeamId ]);

  const initialMode = useMemo(() => {
    if ([ InventoryBatchTypeEnum.damaged, InventoryBatchTypeEnum.productionDamaged ].includes(inventoryBatch.type)) {
      return InventoryTableEditableMode.damaged;
    }

    if (inventoryBatch.type === InventoryBatchTypeEnum.production) {
      return InventoryTableEditableMode.unary;
    }

    return InventoryTableEditableMode.transfer;
  }, [ inventoryBatch.type ]);

  const getDisplayStandardFilters = useCallback((): StandardInventoryTableFilters[] => {
    if (!inventoryBatch.team.isWarehouse) {
      return [  'showNotAvailableAtEvents', 'onlyShowEdits' ];
    }

    return [ 'onlyShowEdits' ];
  }, [ inventoryBatch.team.isWarehouse ]);

  const getExtraColumns = useCallback((inventoryEdits: InventoryEdits, { setEdit }: InventoryTableApi) => {
    const columns: GridColDef<GetProductsResponse['data'][number]>[] = [
      {
        field: 'intialAndReviewQuantities',
        headerName: 'Previous Quantities',
        headerAlign: 'center',
        valueGetter: ({ row }) => inventoryBatch.updates.find(update => update.product === row._id)?.quantity ?? undefined,
        type: 'number',
        width: 250,
        align: 'center',
        renderCell: ({ row }) => {
          return (
            <PreviosQuantitiesCell
              setEdit={setEdit}
              row={row}
              inventoryBatch={inventoryBatch}
              inventoryEdits={inventoryEdits}
            />
          );
        },
        cellClassName: 'padding-0',
      },
    ];

    return columns;
  }, [ inventoryBatch ]);

  const sortedProducts = useMemo(() => {
    // products with updates go first in order of the updates
    const productionWithUpdates = inventoryBatch.updates.map(update => {
      return products.find(product => product._id === update.product)!;
    });
    // products without updates go last in alphabetical order
    const productsWithoutUpdates = products.filter(product => !inventoryBatch.updates.find(update => update.product === product._id));

    return [ ...productionWithUpdates, ...productsWithoutUpdates ];
  }, [ inventoryBatch.updates, products ]);

  const {
    inventoryTableProps,
    editModal,
    inventoryEdits,
    hasEdits,
    setSaveLoading,
    onResetEdits,
    settingsModalOpen,
    setSettingsModalOpen,
    standardFilters,
    setStandardFilters,
    transferConfig,
  } = useInventoryTable({
    products: sortedProducts,
    teams,
    getStockQuantity: stock => inventoryBatch.type === InventoryBatchTypeEnum.productionDamaged ? stock.productionQuantity : stock.quantity,
    settingsArgs: {
      initialMode,
      initialTransferConfig,
      initialTeamIds: [ inventoryBatch.team._id ],
      initialShowStockMeta: false,
      getDisplayStandardFilters,
    },
    apiArgs: {
      validateTransferEdit: () => undefined,
      allowZero: true,
    },
    teamColumnsArgs: {
      compactTransferColumn: true,
      getTeamColumnHeaderSecondary: () => inventoryBatch.type === InventoryBatchTypeEnum.productionDamaged ? 'production stock' : null,
    },
    getExtraColumns,
  });

  const updateMutation = useMutation({
    mutationFn: (input: UpdateInventoryBatchInput) => updateInventoryBatch(inventoryBatch._id, input),
    onSuccess: async (data) => {
      setSaveLoading(false);
      onResetEdits();
      await queryClient.invalidateQueries({ queryKey: QUERY_KEY.INVENTORY_BATCH(inventoryBatch._id) });

      if (data.data.status === InventoryBatchStatusEnum.closed) {
        await queryClient.invalidateQueries({ queryKey: QUERY_KEY.PRODUCTS });
      }
    },
    onError: (e: AxiosError) => {
      setSaveLoading(false);
      snackbar.error(e.response?.data?.error || 'Failed to update batch');
    }
  });

  const onComplete = async () => {
    setSaveLoading('all');
    updateMutation.mutate({
      updates: prepareReviewInventoryEdits(inventoryBatch, warehouseTeamId, inventoryEdits, true),
      status: UpdateInventoryBatchStatusEnum.closed,
    });
  };

  const allProductReviewed = useMemo(() => {
    return inventoryBatch.updates.every(update => inventoryEdits[update.product]?.[inventoryBatch.team._id]);
  }, [ inventoryBatch.team._id, inventoryBatch.updates, inventoryEdits ]);

  return (
    <InventoryBatchLayout
      actions={(
        <>
          {isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager ]) && <CancelBatchButton />}
          <CancelReviewButton disabled={updateMutation.isLoading} />
          <Button variant="contained" disabled={updateMutation.isLoading || !allProductReviewed} onClick={onComplete}>Complete Review</Button>
        </>
      )}
      isEditMode
    >
      {settingsModalOpen && (
        <InventoryTableSettingsModal
          onClose={() => setSettingsModalOpen(false)}
          filterInputs={(
            <InventoryTableStandardFiltersInput
              displayFilters={getDisplayStandardFilters()}
              disableFilters={inventoryBatch.team.isWarehouse ? undefined : [ 'showNotAvailableAtEvents' ]}
              standardFilters={standardFilters}
              setStandardFilters={setStandardFilters}
              teams={teams}
              transferConfig={transferConfig}
            />
          )}
        />
      )}
      <BeforeUnloadPrompt hasEdits={hasEdits} />
      {editModal}
      <Table preserveQuickFilterOnRowsUpdate {...inventoryTableProps} />
    </InventoryBatchLayout>
  );
};

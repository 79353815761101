import { useCurrentUser, useProductsPageContext } from 'contexts';
import { ProductionCards, ProductionTable } from './components';
import { DashboardOutlined, InfoOutlined, TocOutlined } from '@mui/icons-material';
import { useMemo, useState } from 'react';
import { Box, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { GetProductsResponse } from 'api/actions';
import { getProductTotalProduceAmount } from 'helpers';
import { filterResourceBySearch } from 'services';
import { LogRunDrawer } from './components/LogRunDrawer.component';

export type ProductionScheduleProduct = GetProductsResponse['data'][number] & {
  inProduction: number;
  toProduce: number;
  index: number;
};

const enum ViewEnum {
  table = 'table',
  cards = 'cards',
}

export const ProductionSchedule: React.FC = () => {
  const { isAdmin } = useCurrentUser();
  const { products, teams } = useProductsPageContext();
  // Only include team with production enabled in production charts
  const productionTeams = teams.filter((team) => !team.pauseProduction);

  const [ view, setView ] = useState<ViewEnum>(isAdmin ? ViewEnum.table : ViewEnum.cards);
  const [ search, setSearch ] = useState('');

  const transformedProducts: ProductionScheduleProduct[] = products
    .map((product) => {
      return {
        ...product,
        inProduction: productionTeams
          .filter(team => team.isWarehouse)
          .reduce((total, team) => {
            const stock = product.stocks[team._id];

            return total + stock.productionQuantity;
          }, 0),
        toProduce: getProductTotalProduceAmount(productionTeams, product),
      };
    })
    .sort((a, b) => {
      const salesPerA = Math.trunc(a.salesPerEventDate * 100);
      const salesPerB = Math.trunc(b.salesPerEventDate * 100);

      if (!a.toProduce || !b.toProduce) {
        if (!a.toProduce && !b.toProduce) {
          return salesPerB - salesPerA;
        }

        return !a.toProduce ? 1 : -1;
      }


      if (salesPerA === salesPerB) {
        return b.toProduce - a.toProduce;
      }

      return salesPerB - salesPerA;
    })
    .map((product, index) => ({ ...product, index }));

  const searchFilteredProducts = useMemo(() => {
    return filterResourceBySearch(transformedProducts, 'name', search);
  }, [ search, transformedProducts ]);

  const [ logRunProductId, setLogRunProductId ] = useState<string | null>(null);
  const logRunProduct = useMemo(() => products.find(product => product._id === logRunProductId) ?? null, [ logRunProductId, products ]);
  const warehouseTeam = teams.find(team => team.isWarehouse)!;

  const page = useMemo(() => {
    if (view === ViewEnum.cards) {
      return <ProductionCards products={searchFilteredProducts} search={search} setLogRunProductId={setLogRunProductId} />;
    }
    return <ProductionTable products={searchFilteredProducts} productionTeams={productionTeams} setLogRunProductId={setLogRunProductId} />;
  }, [ search, searchFilteredProducts, productionTeams, view ]);

  return (
    <>
      {logRunProduct && (
        <LogRunDrawer
          stock={logRunProduct.stocks[warehouseTeam._id]}
          productionTeams={productionTeams}
          product={logRunProduct}
          onClose={() => setLogRunProductId(null)}
        />
      )}
      <Box display="flex" justifyContent="space-between" mb={1}>
        <form autoComplete="off">
          <TextField
            id="production_search"
            name="production_search"
            placeholder="beatles, kiss, etc."
            size="small"
            label="Search product"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </form>
        <Box display="flex" alignItems={{ sm: 'flex-end', md: 'center' }} flexDirection={{ sm: 'column', md: 'row' }} gap={{ sm: 1, md: 3 }}>
          <Box
            sx={{
              borderRadius: 1,
              display: { xs: 'none', sm: 'flex' },
              alignItems: 'flex-start',
              justifyContent: 'flex-end',
            }}
          >
            <Box border={(theme) => `1px solid ${theme.palette.primary.main}`} px={1} py={1} bgcolor="primary.background" display="flex" alignItems="center" gap={1}>
              <InfoOutlined color="primary" fontSize="small" />
              <Typography  color="primary.main" >
                  Producing for <b>{productionTeams.map((team) => team.name).join(', ')}</b>
              </Typography>
            </Box>
          </Box>

          {isAdmin && (
            <ToggleButtonGroup value={view} onChange={(_, value) => value && setView(value)} exclusive color="primary">
              <ToggleButton value={ViewEnum.cards}><DashboardOutlined /></ToggleButton>
              <ToggleButton value={ViewEnum.table}><TocOutlined /></ToggleButton>
            </ToggleButtonGroup>
          )}
        </Box>
      </Box>
      {page}
    </>
  );
};
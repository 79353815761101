import { Card, Box, Typography, Divider, ListItemButton, Button } from '@mui/material';
import { DashboardSectionEnum } from 'api/actions';
import { DYNAMIC_ROUTES, ROUTING_CONFIG } from 'constants/routing-config';
import { useDashboardData } from 'queries';
import { useNavigate } from 'react-router-dom';
import { LoadingSkeleton } from '../components/LoadingSkeleton.component';

export const ProductionOverviewSection = () => {
  const navigate = useNavigate();
  const { data: { totalProduceAmount, productsNeedProduction, topThreeProducts } = { totalProduceAmount: 0, productsNeedProduction: 0, topThreeProducts: [] }, isInitialLoading } = useDashboardData(DashboardSectionEnum.PRODUCTION_OVERVIEW);

  return (
    <LoadingSkeleton loading={isInitialLoading}>
      <Card variant="outlined" sx={{ borderRadius: 0 }}>
        <Box m={2} mb={6}>
          <Box display="flex" gap={7}>
            <Box>
              <Typography fontWeight={500} variant="h5" display="flex" alignItems="flex-end" gap={0.5}>{totalProduceAmount}<Typography mb={0.1}>Pieces</Typography></Typography>
              <Typography variant="body2" color="text.secondary">Total to produce</Typography>
            </Box>
            <Box>
              <Typography fontWeight={500} variant="h5" display="flex" alignItems="flex-end" gap={0.5}>{productsNeedProduction} <Typography mb={0.1}>Designs</Typography></Typography>
              <Typography variant="body2" color="text.secondary">Need production (at least 1 piece)</Typography>
            </Box>
          </Box>
          <Box my={3}  mx={-3} bgcolor="primary.background">
            <Divider />
            <Box my={3} mx={2}>
              <Typography variant="h6" fontWeight={400} color="primary.dark">NEXT 3</Typography>
              <Typography variant="body2" color="text.secondary">Designs to run</Typography>
              <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="space-between" gap={1} mt={2}>
                {topThreeProducts.map(product => {
                  return (
                    <Box
                      sx={{
                        flex: 1,
                        backgroundColor: 'primary.background',
                        border: '1px solid',
                        borderColor: 'primary.main',
                        borderRadius: 1,
                      }}
                      key={product._id}
                    >
                      <ListItemButton onClick={() => navigate(DYNAMIC_ROUTES.product.createLink({ productId: product._id }))}>
                        <Box
                          sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            py: 1,
                          }}
                        >
                          <Typography variant="h5" color="primary.main" fontWeight={500}>
                            <Typography variant="caption" component="span" color="text.secondary">produce</Typography>
                                &nbsp;{product.toProduce}&nbsp;
                            <Typography variant="caption" component="span" color="text.secondary">total</Typography>
                          </Typography>
                          <Typography variant="body2">{product.name}</Typography>
                        </Box>
                      </ListItemButton>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Divider />
          </Box>
          <Box display="flex" gap={1} mb={2}>
            <Button variant="outlined" color="primary" fullWidth onClick={() => navigate(ROUTING_CONFIG.productionSchedule)}>Go to Production Page</Button>
            <Button variant="contained" color="primary" fullWidth onClick={() => navigate(ROUTING_CONFIG.inventoryBatches)}>Go to Inventory Batches Page</Button>
          </Box>
        </Box>
      </Card>
    </LoadingSkeleton>
  );
};

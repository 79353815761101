import { Card, Box, Typography, Divider, Avatar, CardHeader, CardContent, Button, Alert } from '@mui/material';
import { DashboardSectionEnum } from 'api/actions';
import { useDashboardData } from 'queries';
import React, { useState } from 'react';
import { LoadingSkeleton } from '../components/LoadingSkeleton.component';
import { DateService, getUserInitialsForAvatar } from 'services';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

export const ProductionMistakes = () => {
  const { data: users = [], isInitialLoading } = useDashboardData(DashboardSectionEnum.PRODUCTION_MISTAKES);

  const totalInitialMistakes = users.reduce((acc, user) => acc + user.initialMistakes, 0);
  const totalReviewMistakes = users.reduce((acc, user) => acc + user.reviewMistakes, 0);
  const totalMistakes = totalInitialMistakes + totalReviewMistakes;

  const sortedUsers = users.sort((a, b) => (b.reviewMistakes + b.initialMistakes) - (a.reviewMistakes + a.initialMistakes));

  const [ showAll, setShowAll ] = useState(false);

  return (
    <LoadingSkeleton loading={isInitialLoading}>
      <Card variant="outlined" sx={{ borderRadius: 0 }}>
        <Alert severity="info"><b>Displaying data for last month</b> ({DateService.getFormattedDateRange(DateService.dayjs().subtract(1, 'month'), DateService.dayjs())})</Alert>
        <Box m={2} mb={6}>
          <Box display="flex" gap={7}>
            <Box>
              <Typography fontWeight={500} variant="h5" display="flex" alignItems="flex-end" gap={0.5}>{totalMistakes}</Typography>
              <Typography variant="body2" color="text.secondary">Total mistakes</Typography>
            </Box>
            <Box>
              <Typography fontWeight={500} variant="h5" display="flex" alignItems="flex-end" gap={0.5}>{totalInitialMistakes}</Typography>
              <Typography variant="body2" color="text.secondary">Initial count mistakes</Typography>
            </Box>
            <Box>
              <Typography fontWeight={500} variant="h5" display="flex" alignItems="flex-end" gap={0.5}>{totalReviewMistakes}</Typography>
              <Typography variant="body2" color="text.secondary">Review mistakes</Typography>
            </Box>
          </Box>
          <Box my={3}  mx={-3}>
            <Divider />
            <Box
              my={3}
              mx={2}
              display="grid"
              gap={2}
              gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))"
            >
              {sortedUsers
                .slice(0, showAll ? sortedUsers.length : Math.min(sortedUsers.length, 8))
                .map(user => (
                  <Card key={user._id} variant="outlined" sx={{ border: theme => `1px solid ${theme.palette.primary.main}` }}>
                    <CardHeader
                      avatar={<Avatar src={user.profileImageUrl}>{user.name && getUserInitialsForAvatar(user.name)}</Avatar>}
                      title={user.name}
                      titleTypographyProps={{ variant: 'body1', fontWeight: 500 }}
                      sx={{ px: 2, py: 1 }}
                    />
                    <CardContent sx={{ p: 0, pb: 0 }}>
                      <Divider />
                      <Typography bgcolor="primary.background" sx={{ px: 2 }}>
                        <Typography
                          variant="h6"
                          fontWeight={500}
                          color="primary.dark"
                          component="span"
                          sx={{
                            mr: 1,
                            width: '35px',
                            display: 'inline-block',
                            textAlign: 'right',
                          }}
                        >
                          {user.initialMistakes + user.reviewMistakes}
                        </Typography>
                      total mistakes
                      </Typography>
                      <Divider />
                      <Box px={2}>
                        <Typography variant="body2" color="text.secondary">
                          <Typography
                            variant="h6"
                            component="span"
                            sx={{
                              mr: 1,
                              width: '35px',
                              display: 'inline-block',
                              textAlign: 'right',
                            }}
                          >
                            {user.initialMistakes}
                          </Typography>
                        initial count mistakes
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          <Typography
                            variant="h6"
                            component="span"
                            sx={{
                              mr: 1,
                              width: '35px',
                              display: 'inline-block',
                              textAlign: 'right',
                            }}
                          >
                            {user.reviewMistakes}
                          </Typography>
                        review mistakes
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                ))}
            </Box>
            {users.length  > 8 && (
              <Box display="flex" justifyContent="center" my={1}>
                <Button onClick={() => setShowAll(p => !p)}>
                  <Box display="flex" alignItems="center" gap={1}>
                    {showAll ? <KeyboardArrowUp fontSize="small" /> : <KeyboardArrowDown fontSize="small" />}
                    {showAll ? 'Show less' : `Show more (${users.length - 8})`}
                  </Box>
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Card>
    </LoadingSkeleton>
  );
};

import { Box } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';

export type LazyRenderProps<T extends object = {}> = {
  Component: React.FunctionComponent<T>;
  componentProps?: keyof T extends never ? never : T;
};

export const LazyRender = <T extends object = {}>({ Component, componentProps }: LazyRenderProps<T>) => {
  const [ isVisible, setIsVisible ] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!ref.current) return;

    const element = ref.current;

    const observer = new IntersectionObserver(
      ([ entry ]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target); // Stop observing once visible
        }
      }
    );

    observer.observe(element);

    return () => {
      observer.unobserve(element);
    };
  }, []);

  return (
    <Box ref={ref} sx={{ height: isVisible ? undefined : '100vh' }}>
      {isVisible ? <Component {...(componentProps ?? {} as T)} /> : null}
    </Box>
  );
};

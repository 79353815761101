import { Typography, Box, Stack, Card } from '@mui/material';
import { DashboardSectionEnum } from 'api/actions';
import { eventEnumHelpers } from 'helpers';
import { EnumHelpers } from 'helpers/enums/types';
import { useDashboardData } from 'queries';
import { LoadingSkeleton } from '../components/LoadingSkeleton.component';

export const EventsManagementStatusesStatsSection = () => {
  const { data = { acceptanceStatusHash: {}, applicationStatusHash: {}, participationStatusHash: {}, upcomingEventsCount: 0 }, isInitialLoading } = useDashboardData(DashboardSectionEnum.EVENTS_MANAGEMENT_STATUSES_STATS);

  return (
    <LoadingSkeleton loading={isInitialLoading}>
      <Card sx={{ borderRadius: 0 }}>
        <Box m={2}>
          <Typography fontWeight={500} variant="h5" mt={1} ml={1}>{data.upcomingEventsCount}</Typography>
          <Typography variant="body2" color="text.secondary" ml={1}>Total upcoming events</Typography>


          <Typography color="text.secondary" mb={2} mt={5}>Participation Status</Typography>
          <Box display="flex" flexWrap="wrap" gap={3}>
            {eventEnumHelpers.participationStatus.enumValues.map(status => {
              return <EventsStatusesOverviewNode key={status} value={status} helpers={eventEnumHelpers.participationStatus} count={data.participationStatusHash[status] ?? 0} totalEvents={data.upcomingEventsCount} />;
            })}
          </Box>

          <Typography color="text.secondary" mb={2} mt={5}>Application Status</Typography>
          <Box display="flex" flexWrap="wrap" gap={3}>
            {eventEnumHelpers.applicationStatus.enumValues.map(status => {
              return <EventsStatusesOverviewNode key={status} value={status} helpers={eventEnumHelpers.applicationStatus} count={data.applicationStatusHash[status] ?? 0} totalEvents={data.upcomingEventsCount} />;
            })}
          </Box>

          <Typography color="text.secondary" mb={2} mt={5}>Acceptance Status</Typography>
          <Box display="flex" flexWrap="wrap" gap={3}>
            {eventEnumHelpers.acceptanceStatus.enumValues.map(status => {
              return <EventsStatusesOverviewNode key={status} value={status} helpers={eventEnumHelpers.acceptanceStatus} count={data.acceptanceStatusHash[status] ?? 0} totalEvents={data.upcomingEventsCount} />;
            })}
          </Box>
        </Box>
      </Card>
    </LoadingSkeleton>
  );
};

type EventsStatusesOverviewNodeProps<StatusEnum extends string> = {
  value: StatusEnum;
  helpers: EnumHelpers<StatusEnum>;
  count: number;
  totalEvents: number;
};

const EventsStatusesOverviewNode = <StatusEnum extends string>(props: EventsStatusesOverviewNodeProps<StatusEnum>) => {
  const percentage = Math.round(props.count / props.totalEvents * 100);

  return (
    <Box
      sx={theme => ({
        width: 120,
        height: 110,
        borderRadius: 2,
        borderTop: `12px solid ${theme.palette[props.helpers.getColor(props.value)].main}`,
        background: theme.palette.grey[200],
      })}
    >
      <Stack alignItems="center" justifyContent="space-between" gap={1} my={1}>
        <Typography variant="body2">{props.helpers.getLabel(props.value)}</Typography>
        <Typography fontWeight={600}>{percentage}%</Typography>
        <Typography variant="body2" color="text.secondary">{props.count} events</Typography>
      </Stack>
    </Box>
  );
};

import { RadioButtonUnchecked, CheckCircle } from '@mui/icons-material';
import { ListItem, Box, Checkbox, ListItemText, Typography } from '@mui/material';
import { useQueryClient, useMutation, QueryClient } from '@tanstack/react-query';
import { updateEventToDo } from 'api/actions';
import { Event, EventToDo, User } from 'api/resources';
import { PickFromResource, ResourceWithPopulated } from 'api/resources/resources.types';
import { useAlertSnackbar } from 'components/AlertSnackbar';
import { useMemo } from 'react';
import { DateService } from 'services';

export type ToDoListItemProps = {
  toDo: EventToDo | ResourceWithPopulated<EventToDo, { assignees: PickFromResource<User, 'name'>[] }>;
  leftContent?: React.ReactNode;
  checkboxPosition?: 'start' | 'end';
  event: PickFromResource<Event, 'name' | 'year'>;
  invalidateQueriesHandler: (queryClient: QueryClient) => Promise<void> | void;
};

export const ToDoListItem: React.FC<ToDoListItemProps> = ({ leftContent, checkboxPosition = 'start', toDo, event, invalidateQueriesHandler }) => {
  const queryClient = useQueryClient();
  const snackbar = useAlertSnackbar();
  const updateToDoMutation = useMutation({
    mutationFn: async () => {
      return updateEventToDo(event._id, toDo._id, { completed: !toDo.completed });
    },
    onSuccess: async (_) => {
      const wasCompleted = toDo.completed;

      await invalidateQueriesHandler(queryClient);

      snackbar.success(`Todo ${wasCompleted ? 'unchecked' : 'checked'}`);
    },
    onError: async (_) => {
      const wasCompleted = toDo.completed;

      snackbar.success(`Could not ${wasCompleted ? 'check' : 'uncheck'} todo`);
    }
  });

  const checkboxDisplay = useMemo(() => {
    return (
      <Box>
        <Checkbox
          checked={toDo.completed}
          onChange={() => updateToDoMutation.mutate()}
          icon={<RadioButtonUnchecked />}
          checkedIcon={<CheckCircle />}
          disabled={updateToDoMutation.isLoading}
        />
      </Box>
    );
  }, [ toDo.completed, updateToDoMutation ]);

  return (
    <ListItem
      components={{ Root: 'div' }}
      disableGutters
      disablePadding
    >
      <Box
        display="flex"
        gap={1}
        bgcolor={theme => theme.palette.primary.background}
        borderRadius={1}
        mb={1}
        p={1}
      >
        <Box flex={1}>
          {leftContent}
        </Box>
        <Box display="flex" alignItems="center">
          {checkboxPosition === 'start' && checkboxDisplay}
          <ListItemText
            sx={{ my: 0, width: 300 }}
            primary={
              <Typography sx={{ textDecoration: toDo.completed ? 'line-through' : 'underline' }} width="fit-content">
                {toDo.title}
              </Typography>
            }
            secondary={(
              <Typography
                variant="body2"
                sx={{
                  textDecoration: toDo.completed ? 'line-through' : 'none',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                width="fit-content"
                maxWidth={300}
              >
                {toDo.description && toDo.description + '.\n'}
                {toDo.description && <br />}
                {toDo.dateAsUtc && 'Reminder on '}
                <strong>{toDo.dateAsUtc && DateService.getFormattedDate(DateService.dayjs(toDo.dateAsUtc), 'MMM Do, YYYY h:mma') + '. '}</strong>
                {toDo.dateAsUtc && <br />}
                {/* only show assignees if they're populated */}
                {typeof toDo.assignees[0] !== 'string' && (
                  <>
                    Assignees:&nbsp;
                    <strong>{toDo.assignees.map(assignee => typeof assignee !== 'string' && assignee.name).join(', ')}</strong>
                  </>
                )}
              </Typography>
            )}
          />
          {checkboxPosition === 'end' && checkboxDisplay}
        </Box>
      </Box>
    </ListItem>
  );
};
import { Typography, Box, List, Divider, Card } from '@mui/material';
import { DashboardSectionEnum } from 'api/actions';
import { CardContentLink, EditableImage, ToDoListItem } from 'components';
import { DYNAMIC_ROUTES } from 'constants/routing-config';
import { useDashboardData } from 'queries';
import { QUERY_KEY } from 'queries/query-keys';
import React from 'react';
import { DateService } from 'services';
import { GoodJob } from '../components/GoodJob.component';
import { LoadingSkeleton } from '../components/LoadingSkeleton.component';

export const EventsManagementEventsToDosSection = () => {
  const { data: events = [], isInitialLoading } = useDashboardData(DashboardSectionEnum.EVENTS_MANAGEMENT_TODOS);

  const todosCount = events.reduce((acc, toDo) => acc + toDo.toDos.length, 0);

  return (
    <LoadingSkeleton loading={isInitialLoading}>
      <Card sx={{ borderRadius: 0 }}>
        <Box m={2}>
          <Typography fontWeight={500} variant="h5" mt={1} ml={1}>{todosCount}</Typography>
          <Typography variant="body2" color="text.secondary" ml={1} mb={3}>To-do{todosCount === 1 ? '' : 's'}</Typography>

          {todosCount ?  events.map((event, index, arr) => {
            return (
              <React.Fragment key={event._id}>
                <Box display="flex" justifyContent="space-between" flexDirection={{ xs: 'column', 'lg': 'row' }}>
                  <Box display="flex" gap={1} flex={1}>
                    <EditableImage
                      width={90}
                      height={90}
                      imageUrl={event.iconUrl}
                      disabledEditable
                      onUpload={() => {}}
                    />
                    <Box>
                      <CardContentLink
                        title={`${event.name} (${event.year})`}
                        href={DYNAMIC_ROUTES.event.createLink({ eventId: event._id })}
                      />
                      <Typography>{DateService.getFormattedDateRange(event.startDateAsUtc, event.endDateAsUtc)}</Typography>
                    </Box>
                  </Box>
                  <List>
                    {event.toDos.map((toDo) => (
                      <ToDoListItem
                        checkboxPosition="end"
                        key={toDo._id}
                        event={event}
                        toDo={toDo}
                        invalidateQueriesHandler={(queryClient) => queryClient.invalidateQueries({ queryKey: QUERY_KEY.DASHBOARD(DashboardSectionEnum.EVENTS_MANAGEMENT_TODOS) })}
                      />
                    ))}
                  </List>
                </Box>
                {index < arr.length - 1 && <Box my={2}><Divider /></Box>}
              </React.Fragment>
            );
          }) : <GoodJob description="You have no incomplete to-dos" />}
        </Box>

      </Card>
    </LoadingSkeleton>
  );
};

import { CloseOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Divider, IconButton, Skeleton, TextField, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { GetProductsResponse, GetTeamsResponse, updateProductStocksProductionQuantity } from 'api/actions';
import { ProductProductionStockQuantityUpdateLogTypeEnum } from 'api/resources';
import { PreviewDrawer, ProductionStockUpdateLogsTable, StickyStackItem, useAlertSnackbar, UserCredentialsForm, useValidateMutation, validateUserCredentialsSchema } from 'components';
import { useCurrentUser } from 'contexts';
import { Formik } from 'formik';
import { getProductTotalProduceAmount } from 'helpers';
import { QUERY_KEY } from 'queries/query-keys';
import React, { useMemo, useState } from 'react';
import { ResourceForm } from 'types';
import { useProductsProductionStockUpdateLogs } from 'queries';
import { ProductImageSlider } from './ProductImageSlider.component';
import { DateService } from 'services';

export type LogRunDrawerProps = {
  productionTeams: GetTeamsResponse['data'];
  product: GetProductsResponse['data'][number];
  stock: GetProductsResponse['data'][number]['stocks'][string];
  onClose: () => void;
};

export const LogRunDrawer: React.FC<LogRunDrawerProps> = ({ productionTeams, product, onClose, stock }) => {
  const me = useCurrentUser();
  const snackbar = useAlertSnackbar();
  const queryClient = useQueryClient();

  // Credentials step

  const [ step, setStep ] = useState<'credentials' | 'log'>('credentials');
  const [ userId, setUserId ] = useState<string | null>(null);
  const initialUserCredentialsState: ResourceForm['validateCredentials'] = {
    userId: me._id,
    password: '',
    pin: '',
    credentialType: 'pin',
  };

  const validateMutation = useValidateMutation({
    onConfirm: (userId: string) => {
      setUserId(userId);
      setStep('log');
    }
  });

  // END: Credentials step

  const { data: updateLogs = [], isInitialLoading: updateLogsLoading } = useProductsProductionStockUpdateLogs({ productId: product?._id }, { enabled: !!product });
  const totalProduceAmount = useMemo(() => product ? getProductTotalProduceAmount(productionTeams, product!) : 0, [ productionTeams, product ]);

  const [ runAmount, setRunAmount ] = useState(6);
  const runAmountError = useMemo(() => {
    if (runAmount > 8) {
      return 'Maximum run amount is 8';
    }

    if (runAmount < 0) {
      return 'Minimum run amount is 0';
    }
  }, [ runAmount ]);

  const updateProductStocksProductionQuantityMutation = useMutation({
    mutationFn: async () => updateProductStocksProductionQuantity({
      stocks: [ {
        _id: stock!._id,
        updateAmount: runAmount,
        updatedQuantity: stock!.productionQuantity + runAmount,
        type: ProductProductionStockQuantityUpdateLogTypeEnum.production,
      } ],
      userId: userId!,
    }),
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEY.PRODUCTS);
      await queryClient.invalidateQueries(QUERY_KEY.PRODUCTS_PRODUCTION_STOCK_UPDATE_LOGS({ productId: product!._id }));
      snackbar.success(`Logged ${runAmount} units of ${product!.name}`);

      if ((getProductTotalProduceAmount(productionTeams, product!) - runAmount) <= 0) {
        onClose();
      }

      setRunAmount(6);
    },
  });

  return (
    <PreviewDrawer open>
      <Box height="100%" display="flex" flexDirection="column">
        {step === 'credentials' && (
          <Box px={2} py={3}>
            <Formik
              initialValues={initialUserCredentialsState}
              onSubmit={values => validateMutation.mutateAsync(values)}
              validationSchema={validateUserCredentialsSchema}
            >
              {formik => (
                <>
                  <UserCredentialsForm
                    firstStepText="Who is logging the run?"
                    onPinComplete={(value) => {
                      formik.setSubmitting(true);
                      validateMutation.mutateAsync({ ...formik.values, pin: value });
                    }}
                    clearPinOnComplete
                  />
                  <Box display="flex" justifyContent="flex-end" gap={2} mt={2}>
                    <Button onClick={() => onClose()}>Cancel</Button>
                    <Button onClick={() => formik.handleSubmit()} variant="contained">Submit</Button>
                  </Box>
                </>
              )}
            </Formik>
          </Box>
        )}
        {(step === 'log' && product) ? (
          <>
            <StickyStackItem placement="top" order={0}>
              <Box position="relative" bgcolor="background.paper" zIndex={2000}>
                <Box sx={{ position: 'absolute', top: 8, right: 16, zIndex: 3000 }}>
                  <IconButton onClick={onClose}>
                    <CloseOutlined />
                  </IconButton>
                </Box>
                <ProductImageSlider src={product.imageUrl} sizePx={500} scalingFactor={{ xs: 0.4, sm: 0.6, md: 0.7, lg: 1 }} />
                <Divider />
                <Typography textAlign="center" variant="h5" mt={1}>
                  {product.name}
                </Typography>
                <Box display="flex" justifyContent="center" gap={1}>
                  <Typography variant="h6" sx={{ textDecoration: 'underline' }}>
                    Total amount to produce:
                  </Typography>
                  <Typography variant="h6" mb={1}>
                    {updateProductStocksProductionQuantityMutation.isLoading ? (
                      <span>
                        <Skeleton width={20} />
                      </span>
                    ) : totalProduceAmount}
                  </Typography>
                </Box>
                <Divider />
              </Box>
            </StickyStackItem>

            <Box bgcolor="background.default" pt={3} height="100%">

              <Divider />
              <Box bgcolor="background.paper">
                <Box bgcolor="primary.background" flex={1}>
                  <Box display="flex" flexDirection="column" gap={2} py={3} mx={3}>
                    <Typography>How many pieces are you running?</Typography>

                    <Box display="flex" alignItems="flex-start" gap={1}>
                      <TextField
                        size="small"
                        error={!!runAmountError}
                        helperText={runAmountError}
                        label="Run Amount"
                        type="number"
                        value={runAmount.toString()}
                        onChange={(e) => setRunAmount(Number(e.target.value))}
                        InputProps={{ inputProps: { min: 1, max: 8 }, readOnly: updateProductStocksProductionQuantityMutation.isLoading }}
                        sx={{ width: 150 }}
                      />
                      <LoadingButton
                        disabled={!!runAmountError}
                        loadingPosition="end"
                        loading={updateProductStocksProductionQuantityMutation.isLoading}
                        onClick={() => updateProductStocksProductionQuantityMutation.mutateAsync()}
                        sx={{ pr: 4 }}
                      >
                        Log Run
                      </LoadingButton>
                    </Box>

                    {updateLogs[0] && (
                      <Typography ml={0.5} mt={-1} variant="caption" color="text.secondary" component="div">
                       Last run {DateService.dayjs(updateLogs[0].createdAt).fromNow()} by {updateLogs[0].user.name}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
              <Divider />

              <Box height={400} m={2}>
                <ProductionStockUpdateLogsTable rows={updateLogs} loading={updateLogsLoading} sx={{ maxHeight: '600px' }} />
              </Box>
            </Box>
          </>
        ) : null}
      </Box>
    </PreviewDrawer>
  );
};

import { Autocomplete, CardContentLink, HexColorPicker, RadioGroupYesNoBoolean, SectionCard, SectionCardRow } from 'components';
import React, { useMemo } from 'react';
import { TeamPageSection, TeamPageSectionRowId } from '../types';
import { TeamSectionCardRowEditable } from '../components';
import { updateTeam } from 'api/actions';
import { useTeamOutletContext } from '../Team.base';
import { mixed, object, string } from 'yup';
import { Box, Typography } from '@mui/material';
import { useTeams } from 'queries';
import { DYNAMIC_ROUTES, ROUTING_CONFIG } from 'constants/routing-config';
import { useUsersAsResourceOptions } from 'queries/user';
import { UserEmployeeRoleEnum } from 'api/resources';
import { VehicleForm } from '../forms/Vehicle.form';
import { useCurrentUser } from 'contexts';
import { processFormValueUpdate } from 'helpers';
import { yesNoEnumHelpers } from 'helpers/enums/yes-no-enum.helpers';
import { YesNoEnum } from 'types';

export const OverviewSection = () => {
  const { isAdmin } = useCurrentUser();
  const { data: teams = [], isInitialLoading: teamsLoading } = useTeams();
  const { data: users = [], isInitialLoading: usersLoading } = useUsersAsResourceOptions();
  const { team } = useTeamOutletContext();

  const teamLeadOptions = users
    .filter(user => user.employeeRoles.includes(UserEmployeeRoleEnum.teamLead))
    .map(user => {
      const userTeam = teams.find(team => team.teamLead?._id === user._id);

      return {
        id: user._id,
        label: userTeam ? `${user.name} (assigned to ${userTeam.name})` : user.name,
        disabled: !!userTeam
      };
    });

  const pauseBetchesHelperText = useMemo(() => {
    if (!isAdmin) {
      return 'Only admins can pause batches';
    }

    if (team.hasOpenBatches) {
      return (
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="body2">Inventory cannot be paused because team has open batches: </Typography>
          <CardContentLink title="Batches Page" href={ROUTING_CONFIG.inventoryBatches} variant="body2" />
        </Box>
      );
    }

    return undefined;
  }, [ isAdmin, team.hasOpenBatches ]);

  return (
    <SectionCard title="Overview" id={TeamPageSection.OVERVIEW}>
      <SectionCardRow title="Name">{team.name}</SectionCardRow>
      <TeamSectionCardRowEditable
        title="Color"
        rowId={TeamPageSectionRowId.COLOR}
        formikProps={{
          onSubmit: (values) => updateTeam(team._id, { color: values.color }),
          initialValues: { color: team.color ?? '' },
          validationSchema: object({ color: string().default('') })
        }}
        form={<HexColorPicker name="color" />}
      >
        <Box height={30} width={30} sx={{ backgroundColor: team.color, borderRadius: 1 }} />
      </TeamSectionCardRowEditable>
      <TeamSectionCardRowEditable
        title="Designated Vehicle"
        rowId={TeamPageSectionRowId.VEHICLE}
        formikProps={{
          onSubmit: (values) => updateTeam(team._id, { vehicle: values.vehicle }),
          initialValues: { vehicle: team.vehicle?._id ?? '' },
          validationSchema: object({ vehicle: string().nullable().default(null) })
        }}
        form={<VehicleForm />}
      >
        {team.vehicle && <CardContentLink title={team.vehicle.name} href={DYNAMIC_ROUTES.vehicle.createLink({ vehicleId: team.vehicle._id })} />}
      </TeamSectionCardRowEditable>
      <TeamSectionCardRowEditable
        title="Team Manager"
        rowId={TeamPageSectionRowId.TEAM_LEAD}
        formikProps={{
          onSubmit: (values) => updateTeam(team._id, { teamLead: values.teamLead }),
          initialValues: { teamLead: team.teamLead?._id ?? '' },
          validationSchema: object({ teamLead: string().nullable().default(null) })
        }}
        form={(
          <Autocomplete
            name="teamLead"
            label="Team Manager"
            options={teamLeadOptions}
            getOptionLabel={opt => opt.label}
            transformValue={opt => opt?.id}
            getOptionDisabled={opt => opt.disabled}
            loading={teamsLoading || usersLoading}
          />
        )}
      >
        {team.teamLead && <CardContentLink title={team.teamLead.name} href={DYNAMIC_ROUTES.user.createLink({ userId: team.teamLead._id })} />}
      </TeamSectionCardRowEditable>
      {/* temp */}
      <TeamSectionCardRowEditable
        title="Pause Batches"
        rowId={TeamPageSectionRowId.PAUSE_BATCHES}
        formikProps={{
          onSubmit: (values) => updateTeam(team._id, { pauseBatches: processFormValueUpdate.yesNo(values.pauseBatches) }),
          initialValues: { pauseBatches: team.pauseBatches ? YesNoEnum.yes : YesNoEnum.no },
          validationSchema: object({ pauseBatches: mixed<YesNoEnum>().oneOf([ YesNoEnum.yes, YesNoEnum.no ]) })
        }}
        form={(
          <RadioGroupYesNoBoolean
            name="pauseBatches"
            label="Pause Batches"
            disabled={!isAdmin || team.hasOpenBatches}
            helperText={pauseBetchesHelperText}
            row
          />
        )}
      >
        {yesNoEnumHelpers.yesNo.getLabelForValue(!!team.pauseBatches)}
      </TeamSectionCardRowEditable>
      <TeamSectionCardRowEditable
        title="Pause Production"
        rowId={TeamPageSectionRowId.PAUSE_PRODUCTION}
        formikProps={{
          onSubmit: (values) => updateTeam(team._id, { pauseProduction: processFormValueUpdate.yesNo(values.pauseProduction) }),
          initialValues: { pauseProduction: team.pauseProduction ? YesNoEnum.yes : YesNoEnum.no },
          validationSchema: object({ pauseProduction: mixed<YesNoEnum>().oneOf([ YesNoEnum.yes, YesNoEnum.no ]) })
        }}
        form={(
          <RadioGroupYesNoBoolean
            name="pauseProduction"
            label="Pause Production"
            disabled={!isAdmin}
            helperText={!isAdmin ? 'Only admins can pause production' : undefined}
            row
          />
        )}
      >
        {yesNoEnumHelpers.yesNo.getLabelForValue(!!team.pauseProduction)}
      </TeamSectionCardRowEditable>
    </SectionCard>
  );
};

import { StrictForm, YesNoEnum } from 'types';

export const enum TeamPageSection {
  OVERVIEW = 'overview',
  EVENTS = 'events',
  INVENTORY = 'inventory',
  SALES = 'sales',
}

export const enum TeamPageSectionRowId {
  COLOR = 'color',
  VEHICLE = 'vehicle',
  TEAM_LEAD = 'user',
  PAUSE_BATCHES = 'pause_batches',
  PAUSE_PRODUCTION = 'pause_production',
}

export type TeamSectionRowForm = {
  [TeamPageSectionRowId.COLOR]: StrictForm<{
    color: string;
  }>;
  [TeamPageSectionRowId.VEHICLE]: StrictForm<{
    vehicle: string;
  }>;
  [TeamPageSectionRowId.TEAM_LEAD]: StrictForm<{
    teamLead: string;
  }>;
  [TeamPageSectionRowId.PAUSE_BATCHES]: StrictForm<{
    pauseBatches: YesNoEnum;
  }>;
  [TeamPageSectionRowId.PAUSE_PRODUCTION]: StrictForm<{
    pauseProduction: YesNoEnum;
  }>;
};
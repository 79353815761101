import { Box, Divider, Grid, Typography } from '@mui/material';
import { UserEmployeeRoleEnum } from 'api/resources';
import { JumpNav, LazyRender, PageContentContainer, PageContentHeader, ProductionTimelineChart, StickyStackItem } from 'components';
import { useCurrentUser } from 'contexts';
import { useMemo } from 'react';
import { EventsManagementStatusesStatsSection, EventsManagementEventsToDosSection, EventsConfirmedSection, EventsFeedbackSection, EventsRequestedSection, EventsSelfRequestedSection, ProductionBatchesSection, ProductionOverviewSection } from './sections';
import { DateService } from 'services';
import { ProductionMistakes } from './sections/ProductionMistakes.section';

const enum DashboardPageSection {
  EVENTS_MANAGEMENT = 'events_management',
  PRODUCTION = 'production',
  EVENTS = 'events',
}

export const Dashboard = () => {
  const { isAuthorizedEmployee } = useCurrentUser();

  const initialTab = useMemo(() => {
    if (isAuthorizedEmployee([ UserEmployeeRoleEnum.eventManager ])) {
      return DashboardPageSection.EVENTS_MANAGEMENT;
    }
    if (isAuthorizedEmployee([ UserEmployeeRoleEnum.teamLead, UserEmployeeRoleEnum.teamMember ], false)) {
      return DashboardPageSection.EVENTS;
    }
    if (isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager, UserEmployeeRoleEnum.productionMember ])) {
      return DashboardPageSection.PRODUCTION;
    }

    return null;
  }, [ isAuthorizedEmployee ]);

  const tabs = useMemo(() => initialTab && (
    <JumpNav
      initialValue={initialTab}
      offsetTop={64}
      tabs={[
        { label: 'Events Management', sectionId: DashboardPageSection.EVENTS_MANAGEMENT, hidden: !isAuthorizedEmployee([ UserEmployeeRoleEnum.eventManager ]) },
        { label: 'Events', sectionId: DashboardPageSection.EVENTS, hidden: !isAuthorizedEmployee([ UserEmployeeRoleEnum.teamLead, UserEmployeeRoleEnum.teamMember ], false) },
        { label: 'Production', sectionId: DashboardPageSection.PRODUCTION, hidden: !isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager, UserEmployeeRoleEnum.productionMember ]) },
      ]}
    />
  ), [ initialTab, isAuthorizedEmployee ]);

  return (
    <PageContentContainer
      header={(
        <PageContentHeader
          title="Dashboard"
          sticky
          tabs={tabs}
        />
      )}
    >
      <Box display="flex" flexDirection="column" gap={5}>
        {isAuthorizedEmployee([ UserEmployeeRoleEnum.eventManager ]) && (
          <Box id={DashboardPageSection.EVENTS_MANAGEMENT}>
            <StickyStackItem placement="top" order={1}>
              <Box zIndex={2}>
                <Box display="flex" justifyContent="space-between" bgcolor={theme => theme.palette.background.default}>
                  <Typography variant="h6" my={1}>Events Management</Typography>
                </Box>
                <Box mx={-4}><Divider /></Box>
              </Box>
            </StickyStackItem>

            <Grid container spacing={2} mt={2}>
              <Grid item xs={12} md={6}><LazyRender Component={EventsManagementStatusesStatsSection} /></Grid>
              <Grid item xs={12} md={6}><LazyRender Component={EventsManagementEventsToDosSection} /></Grid>
            </Grid>
          </Box>
        )}


        {isAuthorizedEmployee([ UserEmployeeRoleEnum.teamLead, UserEmployeeRoleEnum.teamMember ], false) && (
          <Box id={DashboardPageSection.EVENTS}>
            <StickyStackItem placement="top" order={1}>
              <Box zIndex={2}>
                <Box display="flex" justifyContent="space-between" bgcolor={theme => theme.palette.background.default}>
                  <Typography variant="h6" my={1}>Events</Typography>
                </Box>
                <Box mx={-4}><Divider /></Box>
              </Box>
            </StickyStackItem>

            <Box mt={2} display="flex" flexDirection="column" gap={3}>
              <Box>
                <Typography variant="h6" fontWeight={400} mb={1}>My Events</Typography>
                <Grid container spacing={2} mt={2}>
                  <Grid item xs={12} sm={6}><LazyRender Component={EventsConfirmedSection} /></Grid>
                  <Grid item xs={12} sm={6}><LazyRender Component={EventsFeedbackSection} /></Grid>
                </Grid>
              </Box>

              <Box>
                <Typography variant="h6" fontWeight={400} mb={1}>Pending Requests</Typography>
                <Grid container spacing={2} mt={2}>
                  <Grid item xs={12} sm={6}><LazyRender Component={EventsSelfRequestedSection} /></Grid>
                  <Grid item xs={12} sm={6}><LazyRender Component={EventsRequestedSection} /></Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        )}


        {isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager, UserEmployeeRoleEnum.productionMember ]) && (
          <Box id={DashboardPageSection.PRODUCTION}>
            <StickyStackItem placement="top" order={1}>
              <Box zIndex={2}>
                <Box display="flex" justifyContent="space-between" bgcolor={theme => theme.palette.background.default}>
                  <Typography variant="h6" my={1}>Production</Typography>
                </Box>
                <Box mx={-4}><Divider /></Box>
              </Box>
            </StickyStackItem>

            <Grid container spacing={2} mt={2}>
              <Grid item xs={12} lg={6}>
                <LazyRender Component={ProductionOverviewSection} />
              </Grid>
              <Grid item xs={12} lg={6}>
                <LazyRender Component={ProductionBatchesSection} />
              </Grid>
              {isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager ]) && (
                <>
                  <Grid item xs={12} lg={4}>
                    <LazyRender Component={ProductionMistakes} />
                  </Grid>
                  <Grid item xs={12} lg={8}>
                    <LazyRender
                      Component={ProductionTimelineChart}
                      componentProps={{ dateRange: { start: DateService.dayjs().subtract(2, 'weeks'), end: DateService.dayjs() } }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        )}
      </Box>
    </PageContentContainer>
  );
};



import { Box, InputAdornment, LinearProgress, Palette, Theme, alpha, styled } from '@mui/material';
import { GridFilterModel, GridSearchIcon, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { DataGridPro, DataGridProProps } from '@mui/x-data-grid-pro';
import { useCallback, useMemo, useRef, useState } from 'react';
import { TableColumn, TableRow } from './Table.types';
import { TableOverlay, TableOverlayType } from './TableOverlay.component';

export type TableProps = DataGridProProps & {
  columns: TableColumn[];
  rows: TableRow[];
  emptyMessage?: TableOverlayType['message'];
  emptyIcon?: TableOverlayType['icon'];

  /**
   * Prevents the quick filter from resetting when rows are updated.
   * **Warning**: Enabling this prop can lead to app crashes under certain circumstances, especially if the quick filter state becomes desynchronized with the updated rows.
   * Use with caution as it may lead to unsafe behavior.
   */
  preserveQuickFilterOnRowsUpdate?: boolean;
};

type ColorGetter = (theme: Theme) => string;

const DataGrid = styled(DataGridPro)<TableProps>(({ theme }) => {
  const getCellClass = (paletteColor: keyof Pick<Palette, 'success' | 'warning' | 'error'> | ColorGetter | { divider: ColorGetter; background: ColorGetter }) => {
    let divider: string = theme.palette.common.white;
    let background: string = theme.palette.common.white;

    switch (typeof paletteColor) {
      case 'string':
        divider = theme.palette[paletteColor].main;
        background = theme.palette[paletteColor].background as string;
        break;
      case 'function':
        divider = paletteColor(theme);
        background = alpha(paletteColor(theme), 0.1);
        break;
      default:
        divider = paletteColor.divider(theme);
        background = paletteColor.background(theme);
        break;
    }

    return {
      background: background,
      borderBottomColor: divider,
    };
  };

  return {
    borderRadius: 'unset',
    overflow: 'hidden',
    maxHeight: '700px',
    boxShadow: 'none',
    background: theme.palette.background.paper,
    '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
      outline: 'none'
    },
    '& .MuiDataGrid-main': {
      borderRadius: 0,
    },
    '& .MuiDataGrid-pinnedColumns': {
      background: theme.palette.background.paper,
    },
    '& .flex-header': {
      '& .MuiDataGrid-columnHeaderTitleContainerContent': {
        flex: 1,
        paddingRight: '10px'
      }
    },
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: theme.palette.background.paper,
      borderTopLeftRadius: 'unset',
      borderTopRightRadius: 'unset',
      minHeight: '30px',
    },
    '& .MuiDataGrid-pinnedColumnHeaders': {
      backgroundColor: theme.palette.background.paper,
    },
    '& .MuiDataGrid-footerContainer': {
      minHeight: '40px',
    },
    '& .MuiDataGrid-booleanCell': {
      '&[data-value="true"]': {
        color: theme.palette.success.main,
      },
      '&[data-value="false"]': {
        color: theme.palette.error.main,
      },
    },
    '& .selected': {
      background: alpha(theme.palette.grey[100], 0.6),
    },
    '& .selected-darker': {
      background: alpha(theme.palette.grey[300], 0.6),
    },
    '& .attention': {
      background: theme.palette.threat.background,
    },
    '& .borderRight': {
      borderRight: '1px solid',
      borderColor: theme.palette.divider
    },
    '& .color-divider': getCellClass({ divider: theme => theme.palette.divider, background: theme => theme.palette.background.default }),
    '& .color-2-1': getCellClass('success'),
    '& .color-3-1': getCellClass('warning'),
    '& .color-4-1': getCellClass('error'),
    '& .color-6-1': getCellClass(theme => theme.palette.common.black),
  };
});

type CustomToolbarProps = {
  hideSearch?: boolean;
  searchOnly?: boolean;
  actions?: React.ReactNode;
};

const CustomToolbar: React.FC<CustomToolbarProps> = (props) => {
  return (
    <GridToolbarContainer sx={{ display: 'flex', alignItems: 'center', p: 1, gap: 2, borderBottom: theme => `1px solid ${theme.palette.divider}` }}>
      {!props.hideSearch && (
        <GridToolbarQuickFilter
          variant="outlined"
          size="small"
          debounceMs={0}
          autoComplete="off"
          InputProps={{
            placeholder: 'Search all cells',
            startAdornment: (
              <InputAdornment position="start">
                <GridSearchIcon fontSize="small" />
              </InputAdornment>
            )
          }}
          sx={{ pb: 0 }}
        />
      )}
      {props.actions}
      {!props.searchOnly && (
        <Box display="flex" alignItems="center" gap={1} flex={1}>
          <GridToolbarFilterButton />
          <GridToolbarColumnsButton />
          <GridToolbarDensitySelector />
          <Box display="flex" flex={1} justifyContent="flex-end">
            <GridToolbarExport />
          </Box>
        </Box>
      )}
    </GridToolbarContainer>
  );
};

export const Table: React.FC<TableProps> = ({
  slots = {},
  emptyMessage,
  emptyIcon,
  preserveQuickFilterOnRowsUpdate,
  ...props
}) => {
  const [ filterModel, setFilterModel ] = useState<GridFilterModel>({ items: [], quickFilterValues: [] });
  const rowsUpdated = useRef(false);

  const overlay = useCallback(() => {
    return <TableOverlay icon={emptyIcon} message={emptyMessage} />;
  }, [ emptyIcon, emptyMessage ]);

  // temp - don't know how to fix 500 a better way
  useMemo(() => {
    rowsUpdated.current = true;

    return props.rows.map(r => r._id).sort().join(', ');
  }, [ props.rows ]);

  const content = useMemo(() => {
    if (!preserveQuickFilterOnRowsUpdate && rowsUpdated.current) {
      setFilterModel({ items: [], quickFilterValues: [] });
      rowsUpdated.current = false;

      return null;
    }

    return (
      <DataGrid
        {...props}
        showColumnVerticalBorder
        disableRowSelectionOnClick
        columnHeaderHeight={props.density ? undefined : 32}
        filterModel={filterModel}
        onFilterModelChange={setFilterModel}
        slots={{
          toolbar: CustomToolbar,
          loadingOverlay: LinearProgress,
          noRowsOverlay: overlay,
          ...slots,
        }}
        sx={{ ...props.sx, '& .MuiDataGrid-cell.padding-0': { p: 0 } }}
      />
    );
  }, [ filterModel, overlay, preserveQuickFilterOnRowsUpdate, props, slots ]);

  return content;
};

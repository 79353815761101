import { Box, Button } from '@mui/material';
import { DashboardSectionEnum } from 'api/actions';
import { EventDateStaffStatusEnum } from 'api/resources';
import { useDashboardData } from 'queries';
import { EventsOverviewStaffStatusCard } from '../components/EventsOverviewStaffStatusCard.comonent';
import { LoadingSkeleton } from '../components/LoadingSkeleton.component';
import { QUERY_KEY } from 'queries/query-keys';

export const EventsRequestedSection = () => {
  const { data: events = [], isInitialLoading } = useDashboardData(DashboardSectionEnum.REQUESTED_EVENTS);

  return (
    <LoadingSkeleton loading={isInitialLoading}>
      <EventsOverviewStaffStatusCard
        title={totalRequests => `Event request${totalRequests === 1 ? '' : 's'} awaiting your decision`}
        events={events}
        invalidateQueriesHandler={queryClient => queryClient.invalidateQueries(QUERY_KEY.DASHBOARD(DashboardSectionEnum.REQUESTED_EVENTS))}
        actions={(updateStaffStatus, loading) => (
          <Box display="flex" gap={0.5} flexWrap="wrap" alignItems="center" justifyContent="center" mt="5px">
            <Button
              color="error"
              variant="outlined"
              size="small"
              sx={{ height: 25 }}
              onClick={(e) => {
                e.stopPropagation();
                updateStaffStatus(EventDateStaffStatusEnum.deniedRequest);
              }}
              disabled={loading}
            >
            Deny
            </Button>
            <Button
              color="success"
              variant="contained"
              size="small"
              sx={{ height: 25 }}
              onClick={(e) => {
                e.stopPropagation();
                updateStaffStatus(EventDateStaffStatusEnum.confirmed);
              }}
              disabled={loading}
            >
            Accept
            </Button>
          </Box>
        )}
      />
    </LoadingSkeleton>
  );
};

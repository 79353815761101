import { Box, Card, Typography } from '@mui/material';
import { DashboardSectionEnum } from 'api/actions';
import { InventoryBatchStatusEnum, UserEmployeeRoleEnum } from 'api/resources';
import { InventoryBatchesTable } from 'components';
import { useCurrentUser } from 'contexts';
import { useDashboardData } from 'queries';
import { LoadingSkeleton } from '../components/LoadingSkeleton.component';

export const ProductionBatchesSection = () => {
  const { isAuthorizedEmployee } = useCurrentUser();
  const { data: batches = [], isInitialLoading } = useDashboardData(DashboardSectionEnum.PRODUCTION_BATCHES);

  const totalOpenInventoryBatches = batches.filter(batch => batch.status === InventoryBatchStatusEnum.open).length;
  const totalReviewInventoryBatches = batches.filter(batch => batch.status === InventoryBatchStatusEnum.review).length;
  const totalManagerReviewInventoryBatches = batches.filter(batch => batch.status === InventoryBatchStatusEnum.managerReview).length;

  return (
    <LoadingSkeleton loading={isInitialLoading}>
      <Card sx={{ borderRadius: 0, minHeight: 410 }}>
        <Box display="flex" gap={7} m={2} mb={3}>
          {isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager ]) && (
            <Box>
              <Typography fontWeight={500} variant="h5">{totalOpenInventoryBatches}</Typography>
              <Typography variant="body2" color="text.secondary">Batch{totalOpenInventoryBatches === 1 ? '' : 'es'} open</Typography>
            </Box>
          )}
          <Box>
            <Typography fontWeight={500} variant="h5">{totalReviewInventoryBatches}</Typography>
            <Typography variant="body2" color="text.secondary">Batch{totalReviewInventoryBatches === 1 ? '' : 'es'} need{totalReviewInventoryBatches === 1 ? 's' : ''} review</Typography>
          </Box>
          {isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager ]) && (
            <Box>
              <Typography fontWeight={500} variant="h5">{totalManagerReviewInventoryBatches}</Typography>
              <Typography variant="body2" color="text.secondary">Batch{totalManagerReviewInventoryBatches === 1 ? '' : 'es'} need{totalManagerReviewInventoryBatches === 1 ? 's' : ''} manager review</Typography>
            </Box>
          )}
        </Box>
        <Box height={310}>
          <InventoryBatchesTable hideActions inventoryBatches={batches} />
        </Box>
      </Card>
    </LoadingSkeleton>
  );
};

import { GetInventoryBatchResponse, GetProductsResponse } from 'api/actions';
import React, { useMemo } from 'react';
import { InventoryEdits, InventoryTableApi } from './hooks';
import { InventoryBatchStatusEnum, InventoryBatchTypeEnum } from 'api/resources';
import { Box, BoxProps, Divider, ListItemButton, Typography } from '@mui/material';
import { CheckCircleOutlined, HighlightOff } from '@mui/icons-material';
import { InventoryBatchStatusChip } from 'components/Chips';

export type PreviosQuantitiesCellProps = {
  inventoryBatch: GetInventoryBatchResponse['data'];
  row: GetProductsResponse['data'][number];
  inventoryEdits: InventoryEdits;
  setEdit: InventoryTableApi['setEdit'];
};

export const PreviosQuantitiesCell: React.FC<PreviosQuantitiesCellProps> = ({ inventoryBatch, row, inventoryEdits, setEdit }) => {
  const batchUpdate = inventoryBatch.updates.find(update => update.product === row._id);
  const hadReview = ![ InventoryBatchTypeEnum.damaged, InventoryBatchTypeEnum.productionDamaged ].includes(inventoryBatch.type);
  const quantity = batchUpdate?.quantity;
  const reviewQuantity = batchUpdate?.reviewQuantity;
  const inventoryEditValue = inventoryEdits[row._id]?.[inventoryBatch.team._id]?.value;

  if (!batchUpdate) {
    return (
      <PreviousQuantityWrapper isError={!!inventoryEditValue}>
        {(icon) => (
          <>
            {!!inventoryEditValue && icon}
            <Typography ml={1} variant="body2" fontStyle="italic" color="text.secondary">No updates</Typography>
          </>
        )}
      </PreviousQuantityWrapper>
    );
  }

  const isMismatch = reviewQuantity !== undefined && quantity !== reviewQuantity;

  if (!isMismatch && hadReview) {
    return (
      <PreviousQuantityWrapper
        sx={{ px: 1 }}
        onClick={() => setEdit({ teamId: inventoryBatch.team._id, productId: row._id, newValue: String(quantity) })}
        isSuccess={inventoryEditValue !== undefined && inventoryEditValue === quantity}
        isError={inventoryEditValue !== undefined &&  inventoryEditValue !== quantity}
      >
        {(icon) => (
          <>
            <InventoryBatchStatusChip value={InventoryBatchStatusEnum.open} size="small" sx={{ width: 60, mr: 0.5 }} />
            <Typography fontWeight={500} display="flex" alignItems="center" justifyContent="center" gap={1} flex={1} >
              {icon}{Math.abs(quantity ?? 0)}{icon}
            </Typography>
            <Typography position="absolute" bottom={2} left="50%" sx={{ transform: 'translateX(-50%)' }} variant="caption" color="text.secondary">match</Typography>
            <InventoryBatchStatusChip value={InventoryBatchStatusEnum.review} size="small" sx={{ width: 60, ml: 0.5 }} />
          </>
        )}
      </PreviousQuantityWrapper>
    );
  }

  return (
    <Box width="100%" height="100%" display="flex" border={(hadReview && inventoryEditValue === undefined) ? '1px solid' : undefined}>
      <PreviousQuantityWrapper
        sx={{ pl: 1 }}
        onClick={() => setEdit({ teamId: inventoryBatch.team._id, productId: row._id, newValue: String(quantity) })}
        isSuccess={inventoryEditValue !== undefined && inventoryEditValue === quantity}
        isError={inventoryEditValue !== undefined &&  inventoryEditValue !== quantity}
      >
        {(icon) => (
          <>
            <InventoryBatchStatusChip value={InventoryBatchStatusEnum.open} size="small" sx={{ width: 60, mr: 0.5 }} />
            <Typography pr={1} fontWeight={500} component="span" display="flex" alignItems="center" justifyContent="flex-end" flex={1} gap={1}>
              {icon}{Math.abs(quantity ?? 0)}
            </Typography>
          </>
        )}
      </PreviousQuantityWrapper>
      {hadReview && !inventoryEditValue && (
        <Divider flexItem orientation="vertical" sx={{ my: -4 }}/>
      )}
      {hadReview && (
        <PreviousQuantityWrapper
          sx={{ pr: 1 }}
          onClick={() => setEdit({ teamId: inventoryBatch.team._id, productId: row._id, newValue: String(reviewQuantity) })}
          isSuccess={inventoryEditValue !== undefined && inventoryEditValue === reviewQuantity}
          isError={inventoryEditValue !== undefined && inventoryEditValue !== reviewQuantity}
        >
          {(icon) => (
            <>
              <Typography pl={1} fontWeight={500} component="span" display="flex" alignItems="center" flex={1} gap={1}>
                {Math.abs(reviewQuantity ?? 0)}{icon}
              </Typography>
              <InventoryBatchStatusChip value={InventoryBatchStatusEnum.review} size="small" sx={{ width: 60, ml: 0.5 }} />
            </>
          )}
        </PreviousQuantityWrapper>
      )}
    </Box>
  );
};

type PreviousQuantityWrapperProps = {
  isSuccess?: boolean;
  isError?: boolean;
  onClick?: () => void;
  children: (icon: React.ReactNode) => React.ReactNode;
  sx?: BoxProps['sx'];
};

const PreviousQuantityWrapper: React.FC<PreviousQuantityWrapperProps> = ({ onClick, children: childrenFn, isSuccess, isError, sx: inputSx }) => {
  const isHightlighted = isSuccess || isError;

  const sx: BoxProps['sx'] = {
    flex: 1,
    position: 'relative',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bgcolor: theme => `${isHightlighted ?  theme.palette[isSuccess ? 'success' : 'error'].background : undefined} !important`,
    border: theme => `1px solid ${isHightlighted ? theme.palette[isSuccess ? 'success' : 'error'].main : 'transparent'}`,
    ...inputSx,
  };

  const children = useMemo(() => {
    return childrenFn((
      <Box component="span" height={20} visibility={isHightlighted ? 'visible' : 'hidden'}>
        {isSuccess
          ? <CheckCircleOutlined fontSize="small" color="success" />
          : <HighlightOff fontSize="small" color="error" />
        }
      </Box>
    ));
  }, [ childrenFn, isHightlighted, isSuccess ]);

  if (onClick) {
    return (
      <ListItemButton onClick={onClick} sx={sx}>
        {children}
      </ListItemButton>
    );
  }

  return <Box sx={sx}>{children}</Box>;
};

import { GetProductsResponse, GetTeamsResponse } from 'api/actions';
import { ProductActiveBaselineTypeEnum, Team } from 'api/resources';

export const getProductTeamBaseline = (
  product: Pick<GetProductsResponse['data'][number], 'activeBaseline' | 'activeWarehouseBaseline' | 'autoBaseline' | 'autoWarehouseBaseline' | 'manualBaseline' | 'manualWarehouseBaseline'>,
  team: GetTeamsResponse['data'][number] | Team,
  type?: ProductActiveBaselineTypeEnum
) => {
  if (team.isWarehouse) {
    if(type === ProductActiveBaselineTypeEnum.auto) {
      return product.autoWarehouseBaseline;
    }
    if(type === ProductActiveBaselineTypeEnum.manual) {
      return (product.manualWarehouseBaseline ?? 0);
    }

    return product.activeWarehouseBaseline;
  }

  if(type === ProductActiveBaselineTypeEnum.auto) {
    return product.autoBaseline;
  }
  if(type === ProductActiveBaselineTypeEnum.manual) {
    return product.manualBaseline ?? 0;
  }

  return product.activeBaseline;
};

export const getProductProduceAmount = (product: GetProductsResponse['data'][number], team: GetTeamsResponse['data'][number]) => {
  const baseline = getProductTeamBaseline(product, team);

  const produce = baseline - product.stocks[team._id].quantity;

  return Math.max(produce, 0);
};

export const getProductTotalProduceAmount = (selectedTeams: GetTeamsResponse['data'], product: GetProductsResponse['data'][number]) => {
  const totalProduceAmount = selectedTeams
    // Product not available at events should only be produced for warehouse teams
    .filter(team => product.availability?.events || team.isWarehouse)
    .reduce((total, team) => {
      return total + getProductProduceAmount(product, team) - product.stocks[team._id].productionQuantity;
    }, 0);

  return Math.max(totalProduceAmount, 0);
};
import { DescriptionOutlined } from '@mui/icons-material';
import { Card, Box, Typography, Divider, Link } from '@mui/material';
import { DashboardSectionEnum } from 'api/actions';
import { CardContentMarkdown } from 'components';
import { useDashboardData } from 'queries';
import React from 'react';
import { DateService } from 'services';
import { EventsCardEvent } from '../components/EventsCardEvent.component';
import { LoadingSkeleton } from '../components/LoadingSkeleton.component';

export const EventsConfirmedSection = () => {
  const { data: events = [], isInitialLoading } = useDashboardData(DashboardSectionEnum.UPCOMING_EVENTS);

  return (
    <LoadingSkeleton loading={isInitialLoading}>
      <Card variant="outlined" sx={{ minHeight: 400, height: '100%', borderRadius: 0 }}>
        <Box m={2}>
          <Typography fontWeight={500} variant="h5">{events.length}</Typography>
          <Typography variant="body2" color="text.secondary" mb={3}>Confirmed upcoming event{events.length === 1 ? '' : 's'}</Typography>
          <Divider />
          {events.map((event, index) => {
            const isToday = event.dates.some(date => DateService.dayjs().isSame(DateService.dayjsTz(date.dateAsUtc), 'day'));

            return (
              <Box key={event._id} my={3}>
                <EventsCardEvent
                  event={event}
                  additionalInfo={isToday ? (
                    <>
                      {event.lodgingPlace && (
                        <Box my={1}>
                          <Divider />
                          <Typography variant="body2" fontWeight={500}>Lodging</Typography>
                          <Typography variant="body2">{event.lodgingPlace.place.name ? `${event.lodgingPlace.place.name}, ` : ''} {event.lodgingPlace.place.address}</Typography>
                          <Typography variant="body2" color="text.secondary">{DateService.getFormattedDateAndTime(event.lodgingPlace.checkIn)} — {DateService.getFormattedDateAndTime(event.lodgingPlace.checkOut)}</Typography>
                          <Typography variant="body2">Confirmation #: {event.lodgingPlace.confirmationNumber ?? 'N/A'}, phone: {event.lodgingPlace.phone ?? 'N/A'}, website: {event.lodgingPlace.website ?? 'N/A'}</Typography>
                          {event.lodgingPlace.notes && (
                            <>
                              <Divider />
                              <CardContentMarkdown maxHeight={75} markdown={event.lodgingPlace.notes} />
                              <Divider />
                            </>
                          )}
                        </Box>
                      )}
                      {!!event.files?.length && (
                        <Box my={1}>
                          {event.files?.map(file => (
                            <Box display="flex" gap={2} key={file._id}>
                              <DescriptionOutlined fontSize="small" />
                              <Link
                                href={file.url}
                                target="_blank"
                                rel="noopener"
                              >
                                {file.name}
                              </Link>
                            </Box>
                          ))}
                        </Box>
                      )}
                    </>
                  ) : null}
                  divider={!!index}
                  highlight={isToday}
                />
              </Box>
            );
          })}
          <Divider />
        </Box>
      </Card>
    </LoadingSkeleton>
  );
};

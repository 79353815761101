import { Button, Typography } from '@mui/material';
import { GridColDef, GridColumnGroup, GridRowClassNameParams } from '@mui/x-data-grid';
import { ProductStock } from 'api/resources';
import flatten from 'lodash/flatten';
import { useProductsPageContext } from 'contexts';
import { GetProductsResponse, GetTeamsResponse } from 'api/actions';
import { getProductProduceAmount, productSalesCountColumns } from 'helpers';
import { ProductionScheduleProduct } from '../ProductionSchedule.page';
import { Table, TeamCell } from 'components';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { useTableActionColumn } from 'hooks';
import { useMemo } from 'react';

const enum PerTeamColumnKeys {
  baseline = 'baseline',
  inStock = 'inStock',
  produce = 'produce',
}

type PerTeamColumnGetters = {
  field: string;
  getValue: (product: ProductionScheduleProduct, team: GetTeamsResponse['data'][number], stock: ProductStock) => string | number;
};

const perTeamColumnsHash: Record<PerTeamColumnKeys, PerTeamColumnGetters> = {
  [PerTeamColumnKeys.baseline]: { field: 'Baseline', getValue: (product, team) => team.isWarehouse ? product.activeWarehouseBaseline : product.activeBaseline },
  [PerTeamColumnKeys.inStock]: { field: 'Stock', getValue: (_, __, stock) => stock.quantity },
  [PerTeamColumnKeys.produce]: { field: 'Produce', getValue: (product, team) => getProductProduceAmount(product, team) },
};

const perTeamColumns= Object.values(perTeamColumnsHash);

const getProductionTableColumns = (productionTeams: GetTeamsResponse['data'], setLogRunProductId: (id: string) => void): GridColDef<ProductionScheduleProduct>[] => {
  const inventoryColumns = flatten(productionTeams.map((team) => {
    return perTeamColumns.map(({ field, getValue }): GridColDef<ProductionScheduleProduct> => {
      return {
        field: `${team._id}-${field}`, // arbitrary
        headerName: field,
        flex: 1,
        width: 60,
        type: 'number',
        valueGetter: (params) => getValue(params.row, team, params.row.stocks[team._id]),
        editable: false,
      };
    });
  }));

  const columns: GridColDef<ProductionScheduleProduct>[] = [
    {
      field: 'name',
      headerName: 'Name',
      width: 130,
      hideable: false,
    },
    ...productSalesCountColumns,
    {
      field: 'createdAt',
      headerName: 'Created At',
      valueGetter: ({ value }) => new Date(value),
      type: 'date',
      width: 150,
    },
    ...inventoryColumns,
    {
      field: 'productionQuantity',
      headerName: 'In production',
      width: 110,
      align: 'center',
      valueGetter: ({ row }) => {
        return row.inProduction;
      },
    },
    {
      field: 'total',
      headerName: 'Produce',
      width: 112,
      align: 'center',
      valueGetter: ({ row }) => row.toProduce,
      renderCell: ({ value }) => {
        return <Typography sx={{ textDecoration: 'underline' }} fontWeight={500}>{value}</Typography>;
      }
    },
    {
      field: 'actions',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      headerName: '',
      width: 90,
      renderCell: ({ row }) => {
        return  (
          <Button size="small" variant="contained" onClick={() => setLogRunProductId(row._id)}>
            Log Run
          </Button>
        );
      }
    },
  ];

  return columns;
};

export type ProductionTableProps = {
  products: ProductionScheduleProduct[];
  productionTeams: GetTeamsResponse['data'];
  setLogRunProductId: (id: string) => void;
};

export const ProductionTable: React.FC<ProductionTableProps> = ({ products, productionTeams, setLogRunProductId }) => {
  const { loading } = useProductsPageContext();

  const { withActionColumn } = useTableActionColumn({ routeTo: ROUTING_CONFIG.productList });
  const productionTableColumnsRaw = getProductionTableColumns(productionTeams, setLogRunProductId);
  const productionTableColumns = withActionColumn(productionTableColumnsRaw);

  const columnGroupingModel = useMemo((): GridColumnGroup[] => {
    return productionTeams.map((team) => {
      const children = perTeamColumns.map(({ field }) => ({ field: `${team._id}-${field}` }));

      return {
        groupId: team._id,
        renderHeaderGroup: () => <TeamCell team={team} />,
        headerAlign: 'center',
        children
      };
    });
  }, [ productionTeams ]);

  return (
    <Table
      rows={products}
      columns={productionTableColumns}
      loading={loading}
      getRowId={(x) => x._id}
      disableRowSelectionOnClick
      initialState={{
        columns: {
          columnVisibilityModel: {
            salesCount: false,
            createdAt: false,
          }
        },
        pinnedColumns: { right: [ 'productionQuantity', 'total', 'actions' ] },
      }}
      experimentalFeatures={{ columnGrouping: true }}
      columnGroupingModel={columnGroupingModel}
      getCellClassName={(params) => {
        return params.row[params.field]?.type === PerTeamColumnKeys.produce ? 'borderRight' : '';
      }}
      getRowClassName={(params: GridRowClassNameParams<GetProductsResponse['data'][number]> ) => {
        const index = params.indexRelativeToCurrentPage;

        return index % 2 ? 'selected-darker' : '';
      }}
      slotProps={{ toolbar: { hideSearch: true } }}
    />
  );
};

import { Box, CardActionArea, CardMedia } from '@mui/material';
import { useState } from 'react';

export type ProductImageSliderProps = {
  src?: string;
  sizePx: number;
  scalingFactor?: { xs: number; sm: number; md: number; lg: number };
  grayScale?: boolean;
};

export const ProductImageSlider: React.FC<ProductImageSliderProps> = ({
  src,
  sizePx,
  scalingFactor = { xs: 1, sm: 0.5, md: 0.7, lg: 1 },
  grayScale
}) => {
  const [ isFull, setIsFull ] = useState(false);

  return (
    <CardActionArea onClick={() => setIsFull(!isFull)}>
      <Box display="flex" justifyContent="center">
        <Box
          sx={{
            position: 'relative',
            width: {
              xs: `${sizePx * scalingFactor.xs}px`,
              sm: `${sizePx * scalingFactor.sm}px`,
              md: `${sizePx * scalingFactor.md}px`,
              lg: `${sizePx * scalingFactor.lg}px`,
            },
            mt: 1,
            mb: isFull ? 1 : 0,
            height: {
              xs: isFull ? `${sizePx * scalingFactor.xs}px` : `${sizePx * scalingFactor.xs / 2}px`,
              sm: isFull ? `${sizePx * scalingFactor.sm}px` : `${sizePx * scalingFactor.sm / 2}px`,
              md: isFull ? `${sizePx * scalingFactor.md}px` : `${sizePx * scalingFactor.md / 2}px`,
              lg: isFull ? `${sizePx * scalingFactor.lg}px` : `${sizePx * scalingFactor.lg / 2}px`,
            },
            overflow: 'hidden',
            transition: 'height 0.2s ease-in-out',
          }}
        >
          <CardMedia
            component="img"
            alt=""
            image={src}
            sx={{
              filter: grayScale ? 'grayscale(100%)' : undefined,
              position: 'absolute',
              height: {
                xs: `${sizePx * scalingFactor.xs}px`,
                sm: `${sizePx * scalingFactor.sm}px`,
                md: `${sizePx * scalingFactor.md}px`,
                lg: `${sizePx * scalingFactor.lg}px`,
              },
              width: '100%',
              left: 0,
              bottom: {
                xs: isFull ? '0' : `-${sizePx * scalingFactor.xs / 2}px`,
                sm: isFull ? '0' : `-${sizePx * scalingFactor.sm / 2}px`,
                md: isFull ? '0' : `-${sizePx * scalingFactor.md / 2}px`,
                lg: isFull ? '0' : `-${sizePx * scalingFactor.lg / 2}px`,
              },
              transition: 'bottom 0.2s ease-in-out',
              objectFit: 'cover',
            }}
          />
        </Box>
      </Box>
    </CardActionArea>
  );
};

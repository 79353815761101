import { ReviewsOutlined } from '@mui/icons-material';
import { Box, Typography, Divider, Button } from '@mui/material';
import { DashboardSectionEnum } from 'api/actions';
import { AddEventFeedbackModal, CardContentContainer } from 'components';
import { useDashboardData } from 'queries';
import { QUERY_KEY } from 'queries/query-keys';
import React, { useMemo, useState } from 'react';
import { ThemePalette } from 'types';
import { EventsCardEvent } from '../components/EventsCardEvent.component';
import { GoodJob } from '../components/GoodJob.component';
import { LoadingSkeleton } from '../components/LoadingSkeleton.component';

export const EventsFeedbackSection = () => {
  const { data: { events, averageFeedbackTimeInDays, ...rest } = { events: [], averageFeedbackTimeInDays: null }, isInitialLoading } = useDashboardData(DashboardSectionEnum.FEEDBACK_EVENTS);

  const averageFeedbackTimeMessageConfig = useMemo((): { text: string | null; color: ThemePalette} => {
    if (averageFeedbackTimeInDays === null) {
      return { text: null, color: 'unknown' };
    }

    if (averageFeedbackTimeInDays <= 7) {
      return { text: 'Wow, quick feedback — thanks a ton! :)', color: 'success' };
    }

    if (averageFeedbackTimeInDays <= 14) {
      return { text: 'Appreciate the feedback — you\'re awesome!', color: 'primary' };
    }

    return { text: 'Thanks for taking the time — better late than never!', color: 'unknown' };
  }, [ averageFeedbackTimeInDays ]);

  const [ addFeedbackId, setAddFeedbackId ] = useState<string | null>(null);

  return (
    <LoadingSkeleton loading={isInitialLoading}>
      {addFeedbackId && (
        <AddEventFeedbackModal
          onClose={() => setAddFeedbackId(null)}
          eventId={addFeedbackId}
          invalidateQueriesHandler={async queryClient => await queryClient.invalidateQueries({ queryKey: QUERY_KEY.DASHBOARD(DashboardSectionEnum.FEEDBACK_EVENTS) })}
        />
      )}
      <CardContentContainer sx={{ minHeight: 400, height: '100%', borderRadius: 0 }}>
        <Box display="flex" alignSelf="flex-start" gap={7} mb={3}>
          <Box>
            <Typography fontWeight={500} variant="h5">{events.length}</Typography>
            <Typography variant="body2" color="text.secondary">Event{events.length === 1 ? '' : 's'} awaiting your feedback</Typography>
          </Box>
          {averageFeedbackTimeInDays !== null && (
            <Box borderRadius={1} border={theme => `1px solid ${theme.palette[averageFeedbackTimeMessageConfig.color].main}`} bgcolor={theme => theme.palette[averageFeedbackTimeMessageConfig.color].background} px={1}>
              <Typography fontWeight={500} lineHeight={2}>Feedback time: {averageFeedbackTimeInDays} day{averageFeedbackTimeInDays === 1 ? '' : 's'}</Typography>
              <Typography variant="body2" color={`${averageFeedbackTimeMessageConfig.color}.main`}>{averageFeedbackTimeMessageConfig.text}</Typography>
            </Box>
          )}
        </Box>
        <Divider />
        {events.length ? events.map((event, index) => {
          return (
            <Box key={event._id} my={3}>
              <EventsCardEvent
                event={event}
                divider={!!index}
                actions={(
                  <Button
                    startIcon={<ReviewsOutlined />}
                    fullWidth
                    variant="contained"
                    onClick={() => setAddFeedbackId(event._id)}
                  >
                     Add feedback
                  </Button>
                )}
                hideDates
              />
            </Box>
          );
        }) : <GoodJob description="You left feedback for all events" />}
        {!!events.length && <Divider />}
      </CardContentContainer>
    </LoadingSkeleton>
  );
};

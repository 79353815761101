import { Box, Divider, lighten } from '@mui/material';
import { EventSalesTimelineChart } from 'components/AnalyticChart';
import { EventDatesReconciliation } from 'components/Event/components';
import { useEventContext } from 'components/Event/event.context';
import { eventPageSectionEnumHelpers } from 'components/Event/helpers';
import { OverviewSection, AssignmentsSection, DatesSection, ToDosSection, PhotosSection, ApplicationSection, LocationSection, LodgingSection, PaymentsSection, InsuranceSection, LinksSection, FilesSection } from 'components/Event/sections';
import { EventPageSection } from 'components/Event/types';
import { SectionCardsEditContextProvider } from 'components/SectionCard';
import { useCurrentUser } from 'contexts';

export const EventPreviewContentMain = () => {
  const { event, eventSales } = useEventContext();
  const currentUser = useCurrentUser();
  const isSectionHidden = (section: EventPageSection) => eventPageSectionEnumHelpers.getIsHidden(section, event, currentUser);

  return (
    <SectionCardsEditContextProvider>
      <Box bgcolor={theme => lighten(theme.palette.background.default, 0.5)}>
        <Box display="flex" flexDirection="column" gap={5} mx={2} py={5}>
          {!isSectionHidden(EventPageSection.OVERVIEW) && <OverviewSection />}
          {!isSectionHidden(EventPageSection.ASSIGNMENTS) && <AssignmentsSection />}
          {!isSectionHidden(EventPageSection.DATES) && <DatesSection />}
          {!isSectionHidden(EventPageSection.TO_DOS) && <ToDosSection />}
          {!isSectionHidden(EventPageSection.SALES) && (
            <Box bgcolor="background.paper" id={EventPageSection.SALES} mx={-2}>
              <Box bgcolor="primary.background">
                <Divider />
                <Box py={5} mx={2}>
                  <EventSalesTimelineChart event={event} />
                  {event.salesCount > 0 && (
                    <Box mt={5}>
                      <EventDatesReconciliation sales={eventSales}/>
                    </Box>
                  )}
                </Box>
                <Divider />
              </Box>
            </Box>
          )}
          {!isSectionHidden(EventPageSection.PHOTOS) && <PhotosSection />}
          {!isSectionHidden(EventPageSection.APPLICATION) && <ApplicationSection />}
          {!isSectionHidden(EventPageSection.PAYMENTS) && <PaymentsSection />}
          {!isSectionHidden(EventPageSection.LOCATION) && <LocationSection />}
          {!isSectionHidden(EventPageSection.LODGING) && <LodgingSection />}
          {!isSectionHidden(EventPageSection.INSURANCE) && <InsuranceSection />}
          {!isSectionHidden(EventPageSection.LINKS) && <LinksSection />}
          {!isSectionHidden(EventPageSection.FILES) && <FilesSection />}
        </Box>
      </Box>
    </SectionCardsEditContextProvider>
  );
};
